.listing-card-vertical {
  margin: 10px;
  border-radius: 2px !important;
  position: relative;
  border: 1px solid rgba(205, 161, 119, 0.3) !important;
  min-height: 677px;
}

.lc-vertical-container-img {
  height: 350px;
  width: 100%;
  display: block;
}

.lc-vertical-container-body {
  width: 100%;
  padding: 10px;
  display: block;
  overflow: hidden;
}

.listing-card-horizontal {
  margin: 10px;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
  position: relative;
  max-height: 350px;
  border: 1px solid rgba(205, 161, 119, 0.3) !important;
}

.lc-horizontal-container-img {
  height: 350px;
  width: 50%;
}

.lc-horizontal-container-body {
  width: 50%;
  padding: 10px;
  overflow: hidden;
  max-height: 350px;
}

.lc-container {
  width: 100%;
  padding: 20px;
  /* border: 1px solid rgba(0, 0, 0, 0.2); */
}

.lc-min-width {
  min-width: 200px;
}

.lc-sub-title {
  width: 100%;
  text-align: left;
  font-size: 1em;
}

.lc-body-home {
  height: 105px;
  overflow: hidden;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cda177;
  margin-bottom: 15px;
  color: white;
}

@media screen and (min-width: 991px) {
  .lc-body {
    height: 175px;
    overflow: hidden;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cda177;
    margin-bottom: 15px;
    color: white;
  }
}

@media screen and (max-width: 990px) {
  .lc-body {
    height: 60px;
    overflow: hidden;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 18px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cda177;
    margin-bottom: 15px;
    color: white;
  }
}

.lc-body {
  margin: 0px !important;
}

.lc-ammenities-container {
  width: 100%;
  text-align: left;
  min-height: 25px;
}

.lc-ammenity {
  /* color: rgba(0, 0, 0, 0.6); */
  color: white;
  opacity: 0.5;
  font-size: 15px;
}

.lc-ammenity-icon {
  margin-right: 12px;
}

.lc-view-btn {
  display: inline-block;
  color: white;
  text-transform: uppercase;
  background-color: transparent;
  border: 2px solid #cda177 !important;
  border-radius: 0px;
  padding: 7px 10px;
  margin-right: 9px;
  margin-top: 9px;
  margin-bottom: 13px;
  font-size: 0.9rem;
  outline: none !important;
  opacity: 0.9;
  width: 135px;
  text-align: center;
}

.lc-view-btn:hover {
  background-color: #cda177;
  color: white;
  border: 2px solid #cda177;
  opacity: 0.8;
  transition: ease-in 5ms;
}

.is-watch-listed {
  color: #cda177 !important;
  border: 1px solid #cda177 !important;
}

.is-watch-listed:hover {
  color: white !important;
  border: 1px solid #cda177 !important;
}

@media screen and (min-width: 700px) {
  .lc-address-text {
    color: #cda177;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 8px !important;
  }

  .lc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 5px !important;
    opacity: 0.7;
  }

  .lc-title {
    width: 100%;
    text-align: left;
    font-size: 1.3em;
    border-bottom: 1px solid #cda177;
    margin-bottom: 15px;
    color: white;
    padding-bottom: 2px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

@media screen and (min-width: 355px) and (max-width: 699px) {
  .lc-address-text {
    color: #cda177;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px !important;
  }

  .lc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px !important;
    opacity: 0.7;
  }

  .lc-title {
    width: 100%;
    text-align: left;
    font-size: 15px;
    font-weight: 400;
    border-bottom: 1px solid #cda177;
    margin-bottom: 15px;
    padding-bottom: 2px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 354px) {
  .lc-address-text {
    color: #cda177;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px !important;
  }

  .lc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px !important;
    opacity: 0.7;
  }

  .lc-title {
    width: 100%;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    border-bottom: 1px solid #cda177;
    margin-bottom: 15px;
    padding-bottom: 2px;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

.card-logo {
  position: absolute;
  right: 20px;
  bottom: 12px;
}

.dev-logo {
  width: 95px;
}

.open-home-card {
  bottom: 0px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 100%;
  overflow: hidden;
  font-size: 16px;
  letter-spacing: 1px;
  padding-top: 6px;
  padding-bottom: 4px;
}


.lc-btn-container {
  /* position: absolute; */
  bottom: 25px;
}