.cc-container {
    margin-left: auto;
    margin-right: auto;
}

.cc-header-container-reversed {
    width: 100%;
    text-align: right;
    padding-top: 20px;
}

.cc-reversed-title {
    font-size: 30px;
    padding-right: 20px;
    margin-bottom: 20px;
    /* color: rgba(0,0,0,0.5); */
    color: white;
    font-weight: 500;
    border-bottom: 2px solid rgba(205, 161, 119, 0.5);
    min-width: 80%;
    text-align: right;
    display: inline-block;
    margin-right: 10px;
}

.cc-header-container-normal {
    width: 100%;
    text-align: left;
    padding-top: 20px;
}

.cc-normal-title {
    font-size: 30px;
    padding-left: 20px;
    margin-bottom: 20px;
    /* color: rgba(0,0,0,0.5); */
    color: white;
    font-weight: 500;
    border-bottom: 2px solid rgba(205, 161, 119, 0.5);
    min-width: 80%;
    text-align: left;
    display: inline-block;
    margin-left: 10px;
}

.slick-prev:before {
    content: '' !important;
}

.slick-prev:hover {
    color: #cda177;
}

.slick-prev {
    /* left: 0px !important; */
    z-index: 9;
    font-size: 60px;
    height: 48px;
    width: 28px;
    transform: translate(0, -100%);
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}

.slick-next:before {
    content: '' !important;
}

.slick-next:hover {
    color: #cda177;
}

.slick-next {
    /* right: 0px !important; */
    color: #cda177;
    z-index: 99;
    z-index: 9;
    font-size: 60px;
    height: 48px;
    width: 28px;
    transform: translate(0, -100%);
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
}