.auth-page-link {
  color: #cda177;
  cursor: pointer;
}

.auth-page-link:hover {
  opacity: 0.6;
}

.auth-page-link:visited {
  color: #cda177;
}

.form-container {
  margin: 15px;
}

.auth-form-btn {
  width: 100%;
  margin: 6px 0px;
}

.valid-feedback {
  text-align: right;
}

.invalid-feedback {
  text-align: right;
}

.auth-close-icon {
  color: #cda177;
  position: absolute;
  left: 16px;
  top: 10px;
  font-size: 32px;
  cursor: pointer;
}

.login-logo-img {
  /* max-height: 120px; */
}
.login-logo-container {
  margin: 15px 0px;
}

.auth-form-title-container {
  width: 100%;
  text-align: left;
}

.auth-form-title {
  font-size: 22px;
  border-bottom: 1px solid #cda177;
  padding-bottom: 4px;
  margin: 10px 20px 20px 20px;
}

.auth-error-text {
  color: #f44336;
  font-weight: 600;
  font-size: 16px;
}

.form-group label {
  color: white;
}

.registration-confirm-container {
  margin: 25px 0px;
}

.registration-confirm-message {
  color: whitesmoke;
  font-size: 20px;
  font-weight: 500;
}

.pass-reset-complete-container {
  margin: 25px 0px;
}

.pass-reset-complete-message {
  color: whitesmoke;
  font-size: 20px;
  font-weight: 500;
}

.email-confirm-container {
  margin: 25px 0px;
}

.email-confirm-message {
  color: whitesmoke;
  font-size: 20px;
  line-height: 50px;
  font-weight: 500;
}

.logout-confirm-container {
  margin: 25px 0px;
}

.logout-confirm-message {
  color: whitesmoke;
  font-size: 20px;
  line-height: 50px;
  font-weight: 500;
}

.auth-link-container {
  margin-bottom: 20px;
}
