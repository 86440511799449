.vertical-carousel-container {
  padding-top: 25px;
  padding-bottom: 25px;
  min-height: 400px;
}

.horizontal-carousel-container {
  padding-top: 10vh;
  padding-bottom: 10vh;
  min-height: 400px;
}

.no-pad-col {
  padding: 0px !important;
}

.height200 {
  min-height: 400px !important;
}

/* alter the main slide image depending on screen width */

/* #premium-carousel-container {
    padding: 80px 30px 30px 30px;
} */

@media only screen and (min-width: 1650px) {
  #premium-carousel-container {
    max-width: 1650px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 1056px) {
  .main-slick-slide {
    position: relative;
    margin: 10px 10px 0px 10px !important;
    border: 1px solid rgba(205, 161, 119, 0.3) !important;
    /* border-bottom: none !important; */
  }
}

.main-slick-slide {
  position: relative;
  margin: 10px 0px 0px 10px;
  border: 1px solid rgba(205, 161, 119, 0.3);
  /* border-right: none; */
}

.main-slick-text-container {
  height: 100%;
  display: flex;
  align-items: flex-end;
  text-align: left;
  font-weight: 600;
  text-decoration: dotted;
}

.main-slick-text {
  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
  padding: 5px 10px;
  font-size: 18px;
  width: 100%;
}

.slick-dots li button:before {
  color: #cda177;
  font-size: 18px;
  line-height: 18px;
  opacity: 0.4;
}

.slick-dots li.slick-active button:before {
  color: #cda177 !important;
  opacity: 1 !important;
}

.image-col-h {
  padding-right: 0px;
  margin: 0px;
}

.info-col-h {
  padding-left: 0px;
  margin: 0px;
}

.slick-dots {
  height: 30px;
}
/* 
.slick-dots li {
    list-style-type: none !important;
} */

/* .custom-dot {
    background-color: white !important;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    opacity: 0.8;
}

.custom-dot-active {
    background-color: #cda177 !important;
    border-radius: 50%;
    height: 30px;
    width: 30px;
} */

@media screen and (max-width: 1089px) {
  .pc-blurb-card {
    max-height: 207px;
    overflow: hidden;
  }
}

.premium-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0px;
  height: 170px;
  width: 100%;
}

@media screen and (min-width: 1350px) {
  .pc-margin {
    margin-right: 110px;
    margin-left: 110px;
  }
}

@media screen and (min-width: 1500px) {
  .pc-margin {
    margin-right: 130px;
    margin-left: 130px;
  }
}

@media screen and (min-width: 1800px) {
  .pc-margin {
    margin-right: 13em;
    margin-left: 13em;
  }
}
