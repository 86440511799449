.pcc-container {
  width: 100%;
  /* height: 100%; */
  padding: 20px;
  position: relative;
  /* border: 1px solid rgba(205, 161, 119, 0.3); */
  margin: 0px 0px 0px 10px;
  border-right: 1px solid;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(205, 161, 119, 0.3);
}

@media only screen and (max-width: 1056px) {
  .pcc-container {
    width: calc(100% - 20px);
    border: 1px solid rgba(205, 161, 119, 0.3) !important;
    border-top: none !important;
    margin: 0px 10px 10px 10px;
    min-height: 285px;
  }
}

@media screen and (min-width: 1490px) {
  .pcc-title {
    width: 100%;
    text-align: left;
    font-size: 1.5em;
    padding-bottom: 5px;
    margin-bottom: 15px;
    white-space: wrap;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    opacity: 0.9;
    text-transform: uppercase;
  }

  .pc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px;
    opacity: 0.7;
  }
}

@media screen and (min-width: 991px) {
  .pcc-title {
    width: 100%;
    text-align: left;
    font-size: 1.3em;
    padding-bottom: 5px;
    margin-bottom: 15px;
    white-space: wrap;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    opacity: 0.9;
    text-transform: uppercase;
  }

  .pc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px;
    opacity: 0.7;
  }
}

@media screen and (max-width: 990px) {
  .pcc-title {
    width: 100%;
    text-align: left;
    font-size: 1em;
    padding-bottom: 5px;
    margin-bottom: 15px;
    white-space: wrap;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    opacity: 0.9;
    text-transform: uppercase;
  }

  .pc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px;
    opacity: 0.7;
  }
}

@media screen and (max-width: 355px) {
  .pc-saletype-text {
    color: #ffffff;
    font-weight: 600;
    font-size: 11px;
    line-height: 18px;
    /* border-left: 3px solid #cda177; */
    /* padding-left: 10px; */
    margin-bottom: 10px;
    opacity: 0.7;
  }
}

.pcc-sub-title {
  width: 100%;
  text-align: left;
  font-size: 1em;
}

.m1 {
  margin-left: 7px;
}

@media screen and (min-width: 1057px) and (max-width: 1089px) {
  .pcc-body {
    /* height: 260px; */
    overflow: hidden;
    width: 100%;
    text-align: left;
    /* position: absolute; */
    /* padding: 0px; */
    font-size: 18px;
    display: block;
    z-index: 1;
    /* white-space: nowrap;
    text-overflow: ellipsis; */
    /* align-items: center; */
    /* margin-bottom: 15px; */
  }
}

@media screen and (min-width: 1089px) {
  .pcc-body {
    /* height: 427px; */
    overflow: hidden;
    width: 100%;
    text-align: left;
    /* position: absolute; */
    /* padding: 0px; */
    font-size: 18px;
    display: block;
    z-index: 1;
    /* white-space: nowrap;
      text-overflow: ellipsis; */
    /* align-items: center; */
    /* margin-bottom: 15px; */
  }
}

@media screen and (max-width: 1056px) {
  .pcc-body {
    /* height: 100px; */
    overflow: hidden;
    width: 100%;
    text-align: left;
    /* position: absolute; */
    /* padding: 0px; */
    font-size: 18px;
    display: block;
    z-index: 1;
    /* white-space: nowrap;
      text-overflow: ellipsis; */
    /* align-items: center; */
    /* margin-bottom: 15px; */
  }
}

.pcc-body * {
  margin-bottom: 0px;
}

.pcc-ammenities-container {
  /* padding-top: 15px;
  padding-left: 10px; */
  /* border-top: 1px solid rgba(0, 0, 0, 0.2); */
  /* width: 90%;
  text-align: center;
  position: absolute;
  bottom: 25px; */
  /* background-color: rgb(0,0,0); */
  /* z-index: 9; */
}

.pcc-ammenity {
  color: rgba(0, 0, 0, 0.6);
}

.pcc-ammenity-icon {
  margin-right: 10px;
}

.view-btn {
  margin-top: 15px;
}

.pc-blurb {
  font-size: 14px;
  opacity: 0.8;
  /* padding-top: 28px; */
  padding-right: 6px;
}

.display-none {
  display: none;
}

.card-logo-pc {
  position: absolute;
  right: 35px;
  bottom: 35px;
}

.card-logo-pc-small {
  position: absolute;
  right: 45px;
  bottom: 20px;
}

.dev-logo-lg {
  width: 120px;
}
